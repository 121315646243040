import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { gsap, Draggable } from 'gsap/all'
import {
  ImageCompareLeft,
  ImageCompareRight,
  ImageCompareMain,
  ImageCompareRange,
  ImageCompareWrapper,
  ImageCompareLeftText,
  ImageCompareRightText,
  ImageCompareSlider,
  ImageCompareSliderMain,
} from './index.style'
import { TextBody } from '../TextStyles'
import AnimateSlideIn from '../animation/AnimateSlideIn'

const ImageCompare = ({
  leftText,
  leftImage,
  rightText,
  rightImage,
  delay,
}) => {
  const [value, setValue] = useState(rightImage ? 50 : 100)
  const sliderRef = useRef()
  const sliderDragRef = useRef()

  const handleRangeChange = e => {
    const value = e.target.value
    const sliderOffset = ((value - 50) / 100) * sliderRef.current.offsetWidth

    setValue(value)

    sliderDragRef.current.style.transform = `translate(-50%, -50%) translate3d(${sliderOffset}px, 0px, 0px)`
  }

  useEffect(() => {
    if (!rightImage) return

    gsap.registerPlugin(Draggable)

    Draggable.create(sliderDragRef.current, {
      type: 'x',
      onDrag: function () {
        setValue(
          ((this.x + sliderRef.current.offsetWidth / 2) /
            sliderRef.current.offsetWidth) *
            100
        )
      },
    })

    const applyDraggableBounds = () => {
      Draggable.get(sliderDragRef.current).applyBounds({
        minX: -sliderRef.current.offsetWidth / 2,
        maxX: sliderRef.current.offsetWidth / 2,
      })
    }

    applyDraggableBounds()

    window.addEventListener('resize', applyDraggableBounds)

    return () => {
      window.removeEventListener('resize', applyDraggableBounds)
    }
  }, [rightImage])

  return (
    <AnimateSlideIn delay={delay}>
      <ImageCompareWrapper>
        {rightImage && (
          <ImageCompareRange
            type="range"
            min="0"
            max="100"
            step="10"
            value={value}
            onChange={handleRangeChange}
          />
        )}

        <ImageCompareMain ref={sliderRef}>
          {leftText && (
            <ImageCompareLeftText>
              <TextBody>{leftText}</TextBody>
            </ImageCompareLeftText>
          )}

          <ImageCompareLeft value={value} singleImage={!rightImage}>
            <GatsbyImage
              image={leftImage.gatsbyImageData}
              alt={leftImage.description}
            />
          </ImageCompareLeft>

          {rightText && (
            <ImageCompareRightText>
              <TextBody>{rightText}</TextBody>
            </ImageCompareRightText>
          )}

          {rightImage && (
            <ImageCompareRight value={value}>
              <GatsbyImage
                image={rightImage.gatsbyImageData}
                alt={rightImage.description}
              />
            </ImageCompareRight>
          )}
        </ImageCompareMain>

        {rightImage && (
          <ImageCompareSlider>
            <ImageCompareSliderMain ref={sliderDragRef} />
          </ImageCompareSlider>
        )}
      </ImageCompareWrapper>
    </AnimateSlideIn>
  )
}

ImageCompare.propTypes = {
  leftText: PropTypes.string,
  leftImage: PropTypes.object,
  rightText: PropTypes.string,
  rightImage: PropTypes.object,
  delay: PropTypes.number,
}

ImageCompare.defaultProps = {
  delay: 0,
}

export default ImageCompare
