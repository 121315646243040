import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import draggableIcon from './images/draggable-icon.svg'

export const ImageCompareWrapper = styled.div`
  position: relative;
  ${clamp('border-radius', 16, 24)}
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  .slick-list & {
    border-radius: 0;
  }
`

export const ImageCompareRange = styled.input`
  position: absolute;
  top: 0;
  opacity: 0;
  pointer-events: none;
`

export const ImageCompareMain = styled.div`
  position: relative;
  color: ${colors.lightGrey};

  ${ImageCompareRange}:focus + & {
    outline: ${colors.darkGrey} auto 0.4rem;
    outline-style: solid;
  }
`

export const ImageCompareLeftText = styled.div`
  position: absolute;
  ${clamp('bottom', 20, 48)}
  ${clamp('left', 32, 66)}
  transform-origin: 0% 100%;
  transform: rotate(-90deg);
  z-index: 1;

  .slick-track & {
    display: none;
  }
`

export const ImageCompareRightText = styled.div`
  position: absolute;
  ${clamp('bottom', 20, 48)}
  ${clamp('right', 32, 66)}
  transform-origin: 100% 100%;
  transform: rotate(-90deg) translate(100%, 100%);
  z-index: 1;

  .slick-track & {
    display: none;
  }
`

export const ImageCompareLeft = styled.div.attrs(({ value, singleImage }) => {
  if (!singleImage) {
    return {
      style: {
        clipPath: `polygon(0% 0%, ${value}% 0%, ${value}% 100%, 0% 100%)`,
      },
    }
  }
})``

export const ImageCompareRight = styled.div.attrs(({ value }) => ({
  style: {
    clipPath: `polygon(100% 0%, ${value}% 0%, ${value}% 100%, 100% 100%)`,
  },
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const ImageCompareSlider = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const ImageCompareSliderMain = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${clamp('width', 46, 60)}
  ${clamp('height', 46, 60)}
  border-radius: 50%;
  background-color: ${colors.blue};
  background-image: url(${draggableIcon});
  background-position: center;
  background-size: contain;
`
