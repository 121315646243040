import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MapImage, MapMain, MapTitle, MapText } from './index.style'
import ScrollSection from '../../ScrollSection'
import Container from '../../Container'
import { Heading2, TextBody } from '../../TextStyles'
import Spacer from '../../Spacer'
import AnimateSplitText from '../../animation/AnimateSplitText'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import { colors } from '../../../styles/vars/colors.style'

const Map = ({ title, text, image }) => (
  <ScrollSection>
    <MapMain>
      <Container>
        <MapTitle>
          <Heading2 as="h3" maxWidth={17} color={colors.blue}>
            <AnimateSplitText>{title}</AnimateSplitText>
          </Heading2>
        </MapTitle>

        <Spacer size={44} />

        <MapText>
          <TextBody maxWidth={32}>
            <AnimateSplitText>{text}</AnimateSplitText>
          </TextBody>
        </MapText>

        <Spacer size={[58, 43]} />

        <MapImage>
          <AnimateSlideIn>
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.description}
            />
          </AnimateSlideIn>
        </MapImage>

        <Spacer size={[110, 240]} />
      </Container>
    </MapMain>
  </ScrollSection>
)

Map.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object,
}

export default Map
