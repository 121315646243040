import React from 'react'
import PropTypes from 'prop-types'
import ScrollSection from '../../ScrollSection'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import ImageCompare from '../../ImageCompare'
import AnimateSplitText from '../../animation/AnimateSplitText'
import { Heading2, TitleSmall, TextBody } from '../../TextStyles'
import Spacer from '../../Spacer'
import Cta from '../../Cta'
import { colors } from '../../../styles/vars/colors.style'
import { DoctorStudyContent } from './index.style'

const DoctorStudy = ({ label, title, text, linkText, linkUrl, image }) => (
  <ScrollSection>
    <Container>
      <Spacer size={[80, 196]} />

      <Grid>
        <GridItem tabletP={6} tabletPAlign="center" desk={5} deskStart={2}>
          <ImageCompare leftImage={image} />
        </GridItem>

        <GridItem tabletP={6} tabletPAlign="center" desk={4} deskStart={8}>
          <DoctorStudyContent>
            <TitleSmall>
              <AnimateSplitText>{label}</AnimateSplitText>
            </TitleSmall>

            <Spacer size={10} />

            <Heading2 color={colors.blue}>
              <AnimateSplitText>{title}</AnimateSplitText>
            </Heading2>

            <Spacer size={[30, 42]} />

            <TextBody>
              <AnimateSplitText>{text}</AnimateSplitText>
            </TextBody>

            <Spacer size={[26, 48]} />

            <Cta
              to={linkUrl}
              label={linkText}
              color={colors.blue}
              newTab
              external
            />
          </DoctorStudyContent>
        </GridItem>
      </Grid>

      <Spacer size={[80, 226]} />
    </Container>
  </ScrollSection>
)

DoctorStudy.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  image: PropTypes.object,
}

export default DoctorStudy
