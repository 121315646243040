import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import {
  StoriesPlayerMain,
  StoriesPlayerToggle,
  StoryVideo,
  StoriesList,
  StoryItem,
  StoryItemSubtitle,
  StoryItemText,
  StoryContent,
  StoryButton,
  StoryInner,
  StoriesPlayerWrapper,
  StoryItemProgress,
} from './index.style'
import { TitleSmall, TextBody, Heading2 } from '../../TextStyles'
import VimeoPlayer from '../../VimeoPlayer'
import IconCrossThin from '../../svgs/IconCrossThin'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'

const StoriesPlayer = ({ stories, openIndex, closeVideoPlayer }) => {
  const playerRef = useRef()
  const storySubtitleRefs = useRef([])
  const storyTextRefs = useRef([])
  const storyTimeline = useRef()
  const [activeStoryIndex, setActiveStoryIndex] = useState(openIndex)
  const [videoProgress, setVideoProgress] = useState(0)

  const openStory = useCallback(
    index => {
      const prevEls = [
        storySubtitleRefs.current[activeStoryIndex],
        storyTextRefs.current[activeStoryIndex],
      ]
      const nextEls = [
        storySubtitleRefs.current[index],
        storyTextRefs.current[index],
      ]

      setVideoProgress(0)

      storyTimeline.current = gsap.timeline({
        defaults: {
          ease: 'power2.inOut',
        },
        onComplete: () => {
          setActiveStoryIndex(index)
          window.scroll.update()
        },
      })

      storyTimeline.current.to(prevEls, { height: 0, duration: 0.3 }).fromTo(
        nextEls,
        {
          height: 0,
        },
        {
          height: 'auto',
          duration: 0.2,
        },
        0
      )
    },
    [activeStoryIndex]
  )

  const closePlayer = () => {
    gsap.to(playerRef.current, {
      opacity: 0,
      ease: 'power2.inOut',
      duration: 0.2,
      onComplete: () => {
        closeVideoPlayer()
      },
    })
  }

  const playNextVideo = useCallback(() => {
    const nextIndex =
      activeStoryIndex === stories.length - 1 ? 0 : activeStoryIndex + 1
    openStory(nextIndex)
  }, [activeStoryIndex, stories, openStory])

  const updateProgress = useCallback(progress => {
    setVideoProgress(progress)
  }, [])

  useEffect(() => {
    gsap.fromTo(
      playerRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        ease: 'power2.inOut',
        duration: 0.2,
      }
    )
  }, [])

  return (
    <StoriesPlayerMain ref={playerRef}>
      <Container>
        <StoriesPlayerWrapper>
          <StoriesPlayerToggle onClick={closePlayer}>
            <IconCrossThin responsive />
          </StoriesPlayerToggle>

          <Grid>
            <GridItem tabletP={6} tabletL={7} desk={8} deskL={9}>
              <StoryVideo>
                <VimeoPlayer
                  id={stories[activeStoryIndex].videoId}
                  onTimeUpdate={updateProgress}
                  endedCallback={playNextVideo}
                  thumbnail={
                    stories[activeStoryIndex].videoThumbnail ||
                    stories[activeStoryIndex].coverImage
                  }
                  autoplay
                />
              </StoryVideo>
            </GridItem>

            <GridItem
              tabletP={6}
              tabletPAlign="center"
              tabletL={5}
              desk={4}
              deskL={3}
            >
              <StoriesList>
                {React.Children.toArray(
                  stories.map((story, storyIndex) => (
                    <StoryItem
                      data-open={storyIndex === activeStoryIndex}
                      data-show={false}
                    >
                      <StoryInner open={storyIndex === activeStoryIndex}>
                        <StoryContent
                          ref={ref =>
                            (storySubtitleRefs.current[storyIndex] = ref)
                          }
                          active={storyIndex === activeStoryIndex}
                        >
                          <StoryItemSubtitle>
                            <TitleSmall>{story.subtitle}</TitleSmall>
                          </StoryItemSubtitle>
                        </StoryContent>

                        <Heading2>{story.title}</Heading2>

                        <StoryContent
                          ref={ref => (storyTextRefs.current[storyIndex] = ref)}
                          active={storyIndex === activeStoryIndex}
                        >
                          <StoryItemText>
                            <TextBody>{story.description.description}</TextBody>
                          </StoryItemText>
                        </StoryContent>

                        <StoryButton
                          onClick={() => openStory(storyIndex)}
                          disabled={storyIndex === activeStoryIndex}
                        >
                          {story.title}
                        </StoryButton>
                      </StoryInner>

                      <StoryItemProgress
                        progress={
                          storyIndex === activeStoryIndex ? videoProgress : 0
                        }
                      />
                    </StoryItem>
                  ))
                )}
              </StoriesList>
            </GridItem>
          </Grid>
        </StoriesPlayerWrapper>
      </Container>
    </StoriesPlayerMain>
  )
}

StoriesPlayer.propTypes = {
  stories: PropTypes.array,
  openIndex: PropTypes.number,
  closeVideoPlayer: PropTypes.func,
}

export default StoriesPlayer
