import React from 'react'
import PropTypes from 'prop-types'
import {
  PageNavContent,
  PageNavImage,
  PageNavMain,
  PageNavMonogram,
} from './index.style'
import ScrollSection from '../ScrollSection'
import Spacer from '../Spacer'
import Monogram from '../svgs/Monogram'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Heading3, TextBodySmall } from '../TextStyles'
import AnimateSlideIn from '../animation/AnimateSlideIn'
import AnimateSplitText from '../animation/AnimateSplitText'
import Cta from '../Cta'
import { colors } from '../../styles/vars/colors.style'

const PageNav = ({ title, text, linkSlug, linkText, image }) => {
  return (
    <ScrollSection>
      <PageNavMain>
        <PageNavMonogram>
          <Monogram responsive />
        </PageNavMonogram>

        <Container>
          <Spacer size={[58, 104]} />

          <Grid>
            <GridItem
              tiny={4}
              tinyAlign="center"
              mobileL={3}
              mobileLStart={10}
              mobileLOrder={2}
              mobileLAlign="center"
            >
              <AnimateSlideIn>
                <PageNavImage>
                  <AnimateSlideIn>
                    <GatsbyImage
                      image={image.gatsbyImageData}
                      alt={image.description}
                    />
                  </AnimateSlideIn>
                </PageNavImage>
              </AnimateSlideIn>
            </GridItem>

            <GridItem
              tiny={8}
              tinyAlign="center"
              mobileL={9}
              mobileLStart={1}
              mobileLOrder={1}
              mobileLAlign="center"
            >
              <PageNavContent>
                <Heading3 as="h4">
                  <AnimateSplitText>{title}</AnimateSplitText>
                </Heading3>

                {text && (
                  <>
                    <Spacer size={16} />

                    <TextBodySmall>
                      <AnimateSplitText>{text}</AnimateSplitText>
                    </TextBodySmall>
                  </>
                )}

                <Spacer size={12} />

                <Cta to={linkSlug} label={linkText} color={colors.blue} />
              </PageNavContent>
            </GridItem>
          </Grid>

          <Spacer size={[58, 104]} />
        </Container>
      </PageNavMain>
    </ScrollSection>
  )
}

PageNav.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  linkSlug: PropTypes.string,
  linkText: PropTypes.string,
  image: PropTypes.object,
}

export default PageNav
