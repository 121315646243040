import styled from 'styled-components'
import { mq } from '../../../styles/vars/media-queries.style'

export const DoctorStudyContent = styled.div`
  ${mq.tabletPMax} {
    padding-top: 1.6rem;
  }

  ${mq.tabletP} {
    padding-left: 1.6rem;
  }

  ${mq.desk} {
    padding-left: 0;
  }
`
