import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import PageNav from '../components/PageNav'
import DoctorStudy from '../components/SuccessStories/DoctorStudy'
import Map from '../components/SuccessStories/Map'
import Stories from '../components/SuccessStories/Stories'

const SuccessStoriesPage = ({ data }) => {
  const {
    seoTitle,
    seoDescription: { seoDescription },
    patientsLabel,
    patientsTitle,
    patientsText: { patientsText },
    doctorsLabel,
    doctorsTitle,
    doctorsText: { doctorsText },
    patientStories,
    doctorStories,
    caseStudyLabel,
    caseStudyTitle,
    caseStudyText: { caseStudyText },
    caseStudyLinkText,
    caseStudyFile,
    caseStudyImage,
    mapTitle,
    mapText: { mapText },
    mapImage,
    pageNavigation,
  } = data.contentfulSuccessStories

  const successStoriesData = [
    {
      label: doctorsLabel,
      title: doctorsTitle,
      text: doctorsText,
      stories: doctorStories,
    },
    {
      label: patientsLabel,
      title: patientsTitle,
      text: patientsText,
      stories: patientStories,
    },
  ]

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        pathname={data.site.siteMetadata.routes.successStories}
      />

      <Stories data={successStoriesData} />

      <DoctorStudy
        label={caseStudyLabel}
        title={caseStudyTitle}
        text={caseStudyText}
        linkText={caseStudyLinkText}
        linkUrl={caseStudyFile.file.url}
        image={caseStudyImage}
      />

      <Map title={mapTitle} text={mapText} image={mapImage} />

      <PageNav
        title={pageNavigation.title}
        text={pageNavigation.text.text}
        linkSlug={pageNavigation.pageLink.slug}
        linkText={pageNavigation.linkText}
        image={pageNavigation.image}
      />

      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        routes {
          successStories
        }
      }
    }
    contentfulSuccessStories {
      seoTitle
      seoDescription {
        seoDescription
      }
      patientsLabel
      patientsTitle
      patientsText {
        patientsText
      }
      doctorsLabel
      doctorsTitle
      doctorsText {
        doctorsText
      }
      patientStories {
        subtitle
        title
        coverImage {
          gatsbyImageData
          description
        }
        description {
          description
        }
        videoId
        videoThumbnail {
          gatsbyImageData
          description
        }
      }
      doctorStories {
        subtitle
        title
        coverImage {
          gatsbyImageData
          description
        }
        description {
          description
        }
        videoId
        videoThumbnail {
          gatsbyImageData
          description
        }
      }
      caseStudyLabel
      caseStudyTitle
      caseStudyText {
        caseStudyText
      }
      caseStudyLinkText
      caseStudyFile {
        file {
          url
        }
      }
      caseStudyImage {
        gatsbyImageData
        description
      }
      mapTitle
      mapText {
        mapText
      }
      mapImage {
        gatsbyImageData
        description
      }
      pageNavigation {
        ...PageLink
      }
    }
  }
`

export default SuccessStoriesPage
