import { transparentize } from 'polished'
import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import { colors } from '../../../styles/vars/colors.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { ContainerMain } from '../../Container/index.style'
import { GridMain } from '../../Grid/index.style'
import { GridItemMain } from '../../GridItem/index.style'

export const StoriesContainer = styled.div`
  background-color: ${colors.darkGrey};
`

export const StoriesMain = styled.div`
  position: ${props => (props.cardsOpen ? 'relative' : 'absolute')};
  overflow: hidden;
  color: ${colors.lightGrey};

  ${mq.tabletP} {
    top: 0;
    left: 0;
    opacity: ${props => (props.cardsOpen ? 1 : 0)};
  }

  ${ContainerMain} {
    overflow: visible;
  }

  ${GridMain} {
    position: relative;
  }

  ${GridItemMain}:nth-child(2) {
    ${mq.tabletPMax} {
      position: ${props => (props.cardsOpen ? 'relative' : 'absolute')};
      pointer-events: ${props => (props.cardsOpen ? undefined : 'none')};
    }
  }
`

export const StoriesToggle = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: 10rem;
  border: 0.1rem solid ${transparentize(0.5, colors.midGrey)};

  ${mq.tabletP} {
    margin-right: 5vw;
  }

  ${mq.deskL} {
    margin-right: 0;
  }
`

export const StoriesToggleBtn = styled.button`
  flex: 1;
  padding-top: 1em;
  padding-bottom: 1em;
  color: ${colors.lightGrey};
  cursor: pointer;
  background-color: transparent;
  border: none;

  :not(:first-child) {
    border-left: 0.1rem solid ${transparentize(0.5, colors.midGrey)};
  }

  p {
    opacity: ${props => (props.active ? 1 : 0.5)};
    transition: opacity 0.15s ease;
  }
`

export const StoriesWrapper = styled.div`
  position: relative;
  right: ${props => (props.open ? undefined : 0)};
  display: flex;
  width: fit-content;
  padding-bottom: 7.2rem;
  pointer-events: ${props => (props.open ? undefined : 'none')};

  ${mq.tabletPMax} {
    margin-top: 4.8rem;
  }

  ${mq.tabletP} {
    overflow: hidden;
  }
`

export const StoriesCarousel = styled.div`
  position: relative;
  display: flex;
  width: fit-content;
  cursor: none;
`

export const StoriesContentWrapper = styled.div`
  position: relative;
  margin-top: 4.8rem;

  ${mq.tabletP} {
    padding-right: 5vw;
    padding-bottom: 7.2rem;
  }

  ${mq.deskL} {
    padding-right: 0;
  }
`

export const StoriesContent = styled.div.attrs(({ active }) => ({
  style: {
    position: active ? 'relative' : 'absolute',
    zIndex: active ? '2' : '1',
  },
}))`
  top: 0;
  left: 0;
  width: 100%;
`

export const StoryCard = styled.div`
  position: relative;
  ${clamp('width', 280, 438, breakpoints.tabletP)}
  overflow: hidden;
  ${clamp('border-radius', 16, 24)}
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  background-color: ${colors.midGrey};

  :before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 175%;
  }

  & + & {
    margin-left: 2.4rem;
  }
`

export const StoryCardBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
`

export const StoryCardPlay = styled.button`
  ${clamp('width', 69, 85)}
  color: ${colors.lightGrey};
  background-color: transparent;
  border: none;
  cursor: pointer;
`

export const StoryCardFooter = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  ${clamp('padding-top', 24, 43)}
  ${clamp('padding-right', 27, 32)}
  ${clamp('padding-bottom', 27, 32)}
  ${clamp('padding-left', 27, 32)}
  background: linear-gradient(to top, ${transparentize(
    0.5,
    colors.darkGrey
  )}, ${transparentize(1, colors.darkGrey)});
`

export const StoryCardFooterMain = styled.div`
  ${clamp('padding-left', 15, 17)}
`
