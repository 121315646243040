import styled from 'styled-components'
import { transparentize } from 'polished'
import { clamp } from '../../../styles/utils/conversion.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { colors } from '../../../styles/vars/colors.style'
import { ContainerMain } from '../../Container/index.style'

export const StoriesPlayerMain = styled.div`
  position: relative;
  ${clamp('padding-top', 140, 188)}
  ${clamp('padding-bottom', 78, 96)}
  z-index: 2;
  color: ${colors.lightGrey};
  background-color: ${colors.darkGrey};

  ${ContainerMain} {
    overflow: visible;
  }
`

export const StoriesPlayerWrapper = styled.div`
  position: relative;
`

export const StoriesPlayerToggle = styled.button`
  position: absolute;
  bottom: calc(100% + 1.3rem);
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${clamp('width', 28, 58)}
  ${clamp('height', 28, 58)}
  margin-left: auto;
  ${clamp('padding', 9, 20)}
  color: ${colors.lightGrey};
  border: 0.1rem solid ${transparentize(0.5, colors.midGrey)};
  border-radius: 50%;
  background: transparent;

  ${mq.tabletP} {
    bottom: 100%;
  }

  svg {
    height: 100%;
    width: 100%;
  }
`

export const StoryVideo = styled.div`
  overflow: hidden;
  ${clamp('border-radius', 16, 24)}
`

export const StoriesList = styled.ul`
  list-style: none;

  ${mq.desk} {
    padding-left: 6.6rem;
  }
`

export const StoryItem = styled.li`
  position: relative;
  ${clamp('padding-top', 18, 20)}
  ${clamp('padding-bottom', 26, 32)}
  border-bottom: 0.1rem solid ${transparentize(0.5, colors.midGrey)};
`

export const StoryItemProgress = styled.div.attrs(({ progress }) => ({
  style: {
    transform: `scaleX(${progress})`,
  },
}))`
  position: absolute;
  right: 0;
  bottom: -0.1rem;
  left: 0;
  transform: scaleX(0);
  height: 0.1rem;
  background-color: ${colors.blue};
  transform-origin: 0% 50%;
  transition: transform 0.15s ease;
`

export const StoryItemSubtitle = styled.div`
  ${mq.tabletPMax} {
    padding-bottom: 0.8rem;
  }
`

export const StoryItemText = styled.div`
  padding-top: 2.6rem;
`

export const StoryContent = styled.div`
  height: ${props => (props.active ? undefined : 0)};
  overflow: hidden;
`

export const StoryInner = styled.div`
  opacity: ${props => (props.open ? 1 : 0.5)};
  transition: opacity 0.2s ease;
`

export const StoryButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  border: none;
  background: transparent;
`
