import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { gsap, Draggable, InertiaPlugin } from 'gsap/all'
import { GatsbyImage } from 'gatsby-plugin-image'

// Components
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import AnimateSplitText from '../../animation/AnimateSplitText'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import ScrollSection from '../../ScrollSection'
import StoriesPlayer from '../StoriesPlayer'
import Spacer from '../../Spacer'

// SVG
import IconVideoPlayButton from '../../svgs/IconVideoPlayButton'

// Styles
import {
  StoriesCarousel,
  StoriesContainer,
  StoriesContent,
  StoriesContentWrapper,
  StoriesMain,
  StoriesToggle,
  StoriesToggleBtn,
  StoriesWrapper,
  StoryCard,
  StoryCardBackground,
  StoryCardFooter,
  StoryCardFooterMain,
  StoryCardPlay,
} from './index.style'
import { Heading1, TitleSmall, TextBody, Heading3 } from '../../TextStyles'
import { StoreDispatch } from '../../../Store'
import { scrollTo } from '../../../utils/utils'

const Stories = ({ data }) => {
  const dispatch = useContext(StoreDispatch)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [tabIndex, setTabIndex] = useState(0)
  const [showContent, setShowContent] = useState(false)
  const [showStoriesPlayer, setShowStoriesPlayer] = useState(null)
  const wrapperRef = useRef()
  const carouselRef = useRef()
  const storyCardRefs = useRef([])
  const firstUpdate = useRef(true)
  const cardOffsetY = 50
  const dragEdgeGap = 24

  const applyDraggableBounds = () => {
    Draggable.get(carouselRef.current).applyBounds({
      minX: -Math.max(
        0,
        carouselRef.current.offsetWidth -
          (window.innerWidth -
            carouselRef.current.getBoundingClientRect().left) +
          dragEdgeGap
      ),
      maxX: 0,
    })
  }

  const resetCarouselPosition = () => {
    gsap.to(carouselRef.current, {
      x: 0,
    })
  }

  const hideCards = callback => {
    gsap.to(storyCardRefs.current, {
      opacity: 0,
      yPercent: cardOffsetY,
      ease: 'power2.in',
      duration: 0.3,
      stagger: 0.1,
      onComplete: () => {
        resetCarouselPosition()

        gsap.delayedCall(0.1, callback)
      },
    })
  }

  const openVideoPlayer = index => {
    setShowContent(false)
    scrollTo(wrapperRef.current, 500)

    hideCards(() => {
      setShowStoriesPlayer(index)
    })
  }

  const closeVideoPlayer = () => {
    setShowContent(true)
    showCards()

    /*
     ** Resize page once video player is closed if smooth scroll is running.
     ** Avoids empty white section at bottom of page
     */

    if (window.scroll?.update) {
      setTimeout(() => {
        window.scroll.update()
      }, 500)
    }
  }

  const showCards = useCallback(() => {
    gsap.to(storyCardRefs.current, {
      opacity: 1,
      yPercent: 0,
      ease: 'power2.out',
      duration: 0.3,
      stagger: 0.1,
      onStart: () => {
        setShowStoriesPlayer(null)
      },
      onComplete: () => applyDraggableBounds(),
    })
  }, [])

  const updateTab = index => {
    setTabIndex(index)
    setShowContent(false)
    hideCards(() => {
      setActiveTabIndex(index)
      window.dispatchEvent(new Event('resize'))
      setShowContent(true)
    })
  }

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
    } else {
      gsap.set(storyCardRefs.current, {
        opacity: 0,
        yPercent: cardOffsetY,
      })
      showCards()
    }
  }, [activeTabIndex, showCards])

  useEffect(() => {
    gsap.registerPlugin(Draggable, InertiaPlugin)

    Draggable.create(carouselRef.current, {
      type: 'x',
      edgeResistance: 0.9,
      inertia: true,
      cursor: 'none',
    })

    applyDraggableBounds()

    gsap.set(storyCardRefs.current, {
      opacity: 0,
      yPercent: cardOffsetY,
    })

    setShowContent(true)
    showCards()

    window.addEventListener('resize', applyDraggableBounds)

    return () => {
      window.removeEventListener('resize', applyDraggableBounds)
    }
  }, [showCards])

  return (
    <ScrollSection>
      <StoriesContainer ref={wrapperRef}>
        <StoriesMain cardsOpen={showStoriesPlayer === null}>
          <Container>
            <Spacer size={[126, 188]} />

            <Grid>
              <GridItem
                tabletP={6}
                desk={4}
                deskStart={2}
                deskL={3}
                deskLStart={2}
              >
                <AnimateSlideIn>
                  <StoriesToggle>
                    {React.Children.toArray(
                      data.map((item, itemIndex) => (
                        <StoriesToggleBtn
                          active={tabIndex === itemIndex}
                          onClick={() => updateTab(itemIndex)}
                        >
                          <TitleSmall>{item.label}</TitleSmall>
                        </StoriesToggleBtn>
                      ))
                    )}
                  </StoriesToggle>
                </AnimateSlideIn>

                <StoriesContentWrapper>
                  {React.Children.toArray(
                    data.map((item, itemIndex) => (
                      <StoriesContent active={activeTabIndex === itemIndex}>
                        <Heading1 as="h2">
                          <AnimateSplitText
                            heading
                            animate={
                              showContent && activeTabIndex === itemIndex
                            }
                          >
                            {data[itemIndex].title}
                          </AnimateSplitText>
                        </Heading1>

                        <Spacer size={[26, 35]} />

                        <TextBody>
                          <AnimateSplitText
                            delay={0.3}
                            animate={
                              showContent && activeTabIndex === itemIndex
                            }
                          >
                            {data[itemIndex].text}
                          </AnimateSplitText>
                        </TextBody>
                      </StoriesContent>
                    ))
                  )}
                </StoriesContentWrapper>
              </GridItem>

              <GridItem tabletP={6} desk={7} deskStart={6}>
                <StoriesWrapper open={showStoriesPlayer === null}>
                  <StoriesCarousel
                    ref={carouselRef}
                    onMouseEnter={() => dispatch({ type: 'SHOW_CURSOR' })}
                    onMouseLeave={() => dispatch({ type: 'HIDE_CURSOR' })}
                  >
                    {React.Children.toArray(
                      data[activeTabIndex].stories.map((story, storyIndex) => (
                        <StoryCard
                          ref={ref => (storyCardRefs.current[storyIndex] = ref)}
                        >
                          <StoryCardBackground>
                            <GatsbyImage
                              image={story.coverImage.gatsbyImageData}
                              alt={story.coverImage.description}
                            />
                          </StoryCardBackground>

                          <StoryCardFooter>
                            <StoryCardPlay
                              onClick={() => openVideoPlayer(storyIndex)}
                              onMouseEnter={() =>
                                dispatch({ type: 'HIDE_CURSOR' })
                              }
                              onMouseLeave={() =>
                                dispatch({ type: 'SHOW_CURSOR' })
                              }
                            >
                              <IconVideoPlayButton responsive />
                            </StoryCardPlay>

                            <StoryCardFooterMain>
                              <TitleSmall>{story.subtitle}</TitleSmall>
                              <Heading3>{story.title}</Heading3>
                            </StoryCardFooterMain>
                          </StoryCardFooter>
                        </StoryCard>
                      ))
                    )}
                  </StoriesCarousel>
                </StoriesWrapper>
              </GridItem>
            </Grid>
          </Container>
        </StoriesMain>

        {showStoriesPlayer !== null && (
          <StoriesPlayer
            stories={data[activeTabIndex].stories}
            openIndex={showStoriesPlayer}
            closeVideoPlayer={closeVideoPlayer}
          />
        )}
      </StoriesContainer>
    </ScrollSection>
  )
}

Stories.propTypes = {
  data: PropTypes.array,
}

export default Stories
