import styled from 'styled-components'

export const MapMain = styled.div`
  text-align: center;
`

export const MapTitle = styled.div`
  h3 {
    margin-left: auto;
    margin-right: auto;
  }
`

export const MapText = styled.div`
  > * {
    margin-left: auto;
    margin-right: auto;
  }
`

export const MapImage = styled.div`
  max-width: 90rem;
  margin-left: auto;
  margin-right: auto;
`
