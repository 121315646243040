import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'
import { colors } from '../../styles/vars/colors.style'
import { transparentize } from 'polished'
import { clamp } from '../../styles/utils/conversion.style'

export const PageNavMain = styled.div`
  position: relative;
  overflow: hidden;
  border-top: 0.1rem solid ${transparentize(0.5, colors.midGrey)};

  p {
    max-width: 44rem;
    margin-left: auto;
    margin-right: auto;

    ${mq.mobileL} {
      margin-right: 0;
    }
  }
`

export const PageNavMonogram = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 25vw;
  height: 100%;
  opacity: 0.15;

  ${mq.tabletP} {
    display: block;
  }

  svg {
    position: relative;
    top: 50%;
    transform: translate(-13.6%, -41%);
  }
`

export const PageNavImage = styled.div`
  overflow: hidden;
  border-radius: 1.2rem;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  ${mq.mobileL} {
    height: 23.6rem;
  }
`

export const PageNavContent = styled.div`
  ${mq.mobileLMax} {
    padding-left: 1rem;
  }

  ${mq.mobileL} {
    ${clamp('padding-right', 32, 64)}
    text-align: right;
  }
`
