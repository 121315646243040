import React from 'react'

const IconCrossThin = ({
  width = 17,
  height = 17,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 17"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <path
        d="M16.0508 0.307617L16.6928 0.949632L0.642427 17L0.000412274 16.358L16.0508 0.307617Z"
        fill={fill}
      />
      <path
        d="M16.6924 16.3582L16.0504 17.0002L0 0.949791L0.642015 0.307776L16.6924 16.3582Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconCrossThin
